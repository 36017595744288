<script>
  export let currentURL;
  export let tableData = {};
  export let buttonNames = [];
  $: tableDataArray = Object.values(tableData);
  $: objKeys = Object.keys(tableData);



  let editRow = async (rowNumber, id) => {
    //get all rows
    let rows = document.getElementById("locationTable").getElementsByTagName("tr");
    //get selected row
    let selectedRow = rows[rowNumber + 1];
    // do we need to edit or save?
    let edit = 0;
    selectedRow.childNodes.forEach((element) => {
      if (element.tagName == "TD") {
        element.childNodes.forEach(async (child) => {
          if (child.tagName == "BUTTON" && child.innerText == "Save") {
            child.innerText = "Edit";
            //dont do anything to edit var
          } else if (child.tagName == "BUTTON" && child.innerText == "Edit") {
            child.innerText = "Save";
            // change edit to 1 to signify its an edit not a save
            edit = 1;
          }
        });
      }
    });
    // if save
    if (edit == 0) {
      let tempObj = {};
      let inputsFound = 0;
      selectedRow.childNodes.forEach((element) => {
        if (element.tagName == "TD") {
          element.childNodes.forEach((child) => {
            if (child.tagName == "INPUT") {
              if (inputsFound == 0) {
                tempObj.serialNumber = child.value;
              }
              if (inputsFound == 1) {
                tempObj.gridlineIntersection = child.value;
              }
              if (inputsFound == 2) {
                tempObj.designArea = child.value;
              }
              inputsFound++;
              child.disabled = true;
            }
          });
        }
      });
      //if updating old record, set id
      if (id == -1) {
        console.log(tempObj);
        const response = await fetch(`${currentURL}/cameraLocations/new`, {
          method: "post",
          mode: "cors",
          body: JSON.stringify(tempObj),
          headers: {
            "Content-Type": "application/json",
          },
        });
        //update tableData with new ID
        tableData[tempObj.serialNumber]._id = await response.json();
        console.log(tableData);
        window.location.reload();

      } else {
        tempObj._id = id;
        const response = await fetch(`${currentURL}/cameraLocations/update/:${id}`, {
          method: "post",
          mode: "cors",
          body: JSON.stringify(tempObj),
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(await response.json());
        window.location.reload();
      }
    }
    // if edit
    if (edit == 1) {
      selectedRow.childNodes.forEach((element) => {
        if (element.tagName == "TD") {
          element.childNodes.forEach((child) => {
            if (child.tagName == "INPUT") {
              child.removeAttribute("disabled");
            }
          });
        }
      });
    }

    

  };

  let deleteRow = async (sn, id) => {
    //post to api to delete record for serial number X
    delete tableData[id];
    tableData = tableData;
    console.log()
    console.log(tableData);

    //post to delete - only if ID is not -1. if it is then the record was never saved and only needs to be deleted from table.
    
    if(id != -1){
      const response = await fetch(`${currentURL}/cameraLocations/delete/:${id}`, {
          method: "post",
          mode: "cors",
        body: JSON.stringify({id}),
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(await response.json());
        window.location.reload();
    }
    

    
  };

let lastRowEditToSave2 = async (serialNumber) => {
  let selectedRow = document.getElementById(`${serialNumber}`);
  selectedRow.childNodes.forEach((element) => {
      if (element.tagName == "TD") {
        element.childNodes.forEach((child) => {
          if (child.tagName == "BUTTON" && child.innerText == "Edit") {
            selectedRow.childNodes.forEach((element1) => {
              if (element1.tagName == "TD") {
                element1.childNodes.forEach((child1) => {
                  if (child1.tagName == "INPUT") {
                    child1.removeAttribute("disabled");
                  }
                });
              }
            });
            //also update edit button to save button
            child.innerText = "Save";
          }
        });
      }
    });
}


  let addNewRow = async (serialNumber) => {
    let tempObj = {};
    tempObj._id = -1;
    tempObj.serialNumber = serialNumber;
    tempObj.gridlineIntersection = "";
    tempObj.designArea = "";

    tableData[serialNumber] = tempObj;

    const index = buttonNames.indexOf(serialNumber);
    buttonNames.splice(index, 1);
    buttonNames = buttonNames;
    //post to api to create new record

    setTimeout(() => {
      lastRowEditToSave2(serialNumber);
    }, "10");
  };
</script>

{#if buttonNames.length != 0}
  <div>
    {#each buttonNames as textValue}
      <button on:click={async () => addNewRow(textValue)}>Add {textValue} </button>
    {/each}
  </div>
{:else}
  <div>All Cameras Have Location Data</div>
{/if}

{#if objKeys.length != 0}
  <!-- If data IS loaded-->
  <table id="locationTable">
    <thead>
      <tr id='tableHeader'>
        {#each Object.keys(tableData[objKeys[0]]) as title, i}
          {#if i == 0}
            <th>No.</th>
            {:else if i == 1}
            <th>Serial Number</th>
            {:else if i == 2}
            <th>grid-line Intersection</th>
            {:else if i == 3}
            <th>design Area</th>
            {:else if i == 4}
            <th></th>
          {:else}
            <th>{title}</th>
          {/if}
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each tableDataArray as rowData, k}
        <tr id={rowData.serialNumber}>
          {#each Object.values(rowData) as cellData, i}
            {#if i != 0 && i != 4}
              <td><input type="text" disabled="true" value={cellData} /></td>
            
            {:else if i == 0}
              <td>{k + 1}</td>

              {:else}
              <!---->
            {/if}
          {/each}
          <td><button on:click={() => editRow(k, rowData["_id"])}>Edit</button></td>
          <td><button on:click={() => deleteRow(rowData["serialNumber"], rowData["_id"])}>Delete</button></td>
        </tr>
      {/each}
    </tbody>
    <tfoot></tfoot>
  </table>
{:else}
  <!-- If no data loaded-->
{/if}

<style>
  th {
    padding: 0 2.5vw;
    text-align: center;
  }
  td {
    text-align: center;
  }
</style>
