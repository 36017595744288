<script>
  import CameraLocations from "../../components/global/pages/cameraLocations/cameraLocations.svelte";
  export let currentURL = localServerURL;
  // declare a varible to hold the data from the fetch
  let result = {};
  let serialNumbersToAdd = [];

  //perform fetch and assign the result to the above varible
  async function getCameralocationData() {
    await fetch(`${currentURL}/cameraLocations`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((data) => {
        return data.json();
      })
      .then(async (cameraLocations) => {
        for (const listing in cameraLocations) {
          if (Object.hasOwnProperty.call(cameraLocations, listing)) {
            const cameraLocationObj = cameraLocations[listing];
            console.log(cameraLocationObj);
            let tempKey = cameraLocationObj._id;
            result[tempKey] = cameraLocationObj;
          }
        }
      });
  }

  let allSerialNumbers = [];
  async function getAllSerialNumbers() {
    await fetch(`${currentURL}/cameraData/getAllSerialNumbers`, {
      method: "GET",
    })
      .then((data) => {
        return data.json();
      })
      .then(async (serialNumbers) => {
        for (const serialNumber in serialNumbers) {
          if (Object.hasOwnProperty.call(serialNumbers, serialNumber)) {
            const serial = serialNumbers[serialNumber];
            allSerialNumbers = [...allSerialNumbers, serial];
          }
        }
      });
  }

  let allSerialNumbersWithLocationData = [];
  async function getAllSerialNumbersWlocation() {
    await fetch(`${currentURL}/cameraLocations/getAllSerialNumbers`, {
      method: "GET",
    })
      .then((data) => {
        return data.json();
      })
      .then(async (serialNumbers) => {
        for (const serialNumber in serialNumbers) {
          if (Object.hasOwnProperty.call(serialNumbers, serialNumber)) {
            const serial = serialNumbers[serialNumber];
            allSerialNumbersWithLocationData = [...allSerialNumbersWithLocationData, serial];
          }
        }
      });
  }

  //
  //IFFIE to run at component initialization

  (async function () {
    await getCameralocationData();
    await getAllSerialNumbersWlocation();
    await getAllSerialNumbers();

    for (const serialNumber of allSerialNumbers) {
      if (allSerialNumbersWithLocationData.includes(serialNumber)) {
      } else {
        serialNumbersToAdd = [...serialNumbersToAdd, serialNumber];
      }
    }
  })();
</script>

<CameraLocations bind:tableData={result} bind:buttonNames={serialNumbersToAdd} bind:currentURL />

<style>
</style>
