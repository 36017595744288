<script>
  import { Router, Link, Route } from "svelte-routing";
  export let url = "";

  //import partials
  import Footer from "./components/partials/footer.svelte";
  import Header from "./components/partials/header.svelte";
  //import Pages
  import LandingPage from "./routes/landingPage/+page.svelte";
  import Login from "./routes/login/+page.svelte";
  import Register from "./routes/register/+page.svelte";
  import CameraLocations from "./routes/cameraLocations/+page.svelte";
  import CameraData from "./routes/cameraData/+page.svelte";

  //nav link objects to pass to the header
  let loginLinks = [{ href: "/", linkText: "Go Back" }];

  let cameraDataLinks = [
    { href: "/cameraLocations", linkText: "Camera Locations" },
    { href: "/", linkText: "" },
    { href: "/register", linkText: "Register User Account" },
    { href: "/", linkText: "Log Out" },
  ];
  let cameraLocationLinks = [
    { href: "/cameraData", linkText: "Camera Data" },
    { href: "/", linkText: "" },
    { href: "/", linkText: "Log Out" },
  ];
  let landingPageLinks = [{ href: "/login", linkText: "Log In" }];
  let registerLinks = [
    { href: "/cameraData", linkText: "Camera Data" },
    { href: "/cameraLocations", linkText: "Camera Locations" },
    { href: "/", linkText: "" },
    { href: "/", linkText: "Log Out" },
  ];

  let liveServerURL = "https://laser-edcs-backend.onrender.com";
  let liveServerDNS = "https://westernedcs.ca"
  let localServerURL = "http://localhost:5001";

  //change which is commented dependant on where youre working
  let currentURL = liveServerURL;
  //let currentURL = liveServerDNS;
  //let currentURL = localServerURL;
</script>

<Router {url}>
  <Route path="/">
    <Header navList={landingPageLinks} />
    <LandingPage />
  </Route>
  <Route path="login">
    <Header title={"login"} navList={loginLinks} />
    <Login bind:currentURL />
  </Route>
  <Route path="register">
    <Header title={"register"} navList={registerLinks} />
    <Register bind:currentURL />
  </Route>
  <Route path="cameraData">
    <Header title={"Camera Data"} navList={cameraDataLinks} />
    <CameraData bind:currentURL />
  </Route>
  <Route path="cameraLocations">
    <Header title={"Camera locations"} navList={cameraLocationLinks} />
    <CameraLocations bind:currentURL />
  </Route>
  <Footer />
</Router>

<style>
</style>
