<script>
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

let UTCFilter = {"imdb.rating": {$gte: 8}};

let src = 'https://charts.mongodb.com/charts-project-0-prjdxgu/embed/dashboards?id=5d41b201-6b4a-4b31-a4c2-38e606e6dcb5&theme=dark&autoRefresh=true&maxDataAge=300&showTitleAndDesc=true&scalingWidth=scale&scalingHeight=scale&filter=%7B%20%22UTC%22%3A%20%7B%20%24gte%3A%201716474600%20%7D%20%7D'

/* - current filter: { "UTC": { $gte: 1716474600 } }  - encode and decode @ https://meyerweb.com/eric/tools/dencoder/*/

</script>

<iframe style="background: #21313C;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);width: 100vw;height: 100vh;"  src={src}></iframe>

<style>
    
</style>