<script>
import LandingPage from "../../components/global/pages/landingPage/landingPage.svelte";
</script>



<LandingPage landingMainClass={'container'} WelcomeText={'Welcome to the Affordable'} LabText={"Housing Community Lab's"} toolText={'Online Tool'}  />

<style>

</style>

