<script>
export let currentURL = localServerURL;
import CameraData from '../../components/global/pages/cameraData/cameraData.svelte'
</script>


<CameraData />

<style>

</style>

